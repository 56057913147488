import {Radio, Row, Space} from "antd";

import React, {useEffect, useState} from "react";
import {
    DataSeriesIdField,
    EditButtons,
    formRowHeight,
    InfoArea,
    DataSeriesRecordEditor
} from "./DataSeriesDataDisplayCommon";


export const IdDataSeriesBulkEditor = (props) => {
    const {
        dataType,
        searchItem,
        setSearchItem,
        replaceItem,
        setReplaceItem,
        validateNonEmptyItems,
        editPreview,
        fieldErrorComputedText,
        errorText,
        infoText,
        editorMethods,
        singleEdit
    } = props;


    const onReset = () => {
        setSearchItem(undefined);
        setReplaceItem(undefined);

        validateNonEmptyItems(undefined, undefined);
    };


    const onRun = () => {
        editorMethods.onItemReplRun(searchItem, replaceItem);
    };


    const onTest = () => {
        // TODO: Implement me.
    };


    useEffect(() => {
        validateNonEmptyItems(searchItem, replaceItem);

        return () => {
            validateNonEmptyItems(searchItem, replaceItem);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleEdit, searchItem, replaceItem]);


    // Widths in ems.
    const labelWidth = 6;
    const fieldWidth = 13;


    return (
        <Row size={12} align={"top"}>
            <Space size={12} direction={"vertical"}>
                <Row style={{height: formRowHeight}}>
                    <span><b>Search and replace</b></span>
                </Row>
                <DataSeriesIdField
                    title={'Search'}
                    dataType={dataType}
                    value={searchItem}
                    otherValue={replaceItem}
                    validateItems={validateNonEmptyItems}
                    height={formRowHeight}
                    labelWidth={labelWidth}
                    fieldWidth={fieldWidth}
                    onChange={(value) => {
                        setSearchItem(value);
                    }}
                />
                <DataSeriesIdField
                    title={'Replace'}
                    dataType={dataType}
                    value={replaceItem}
                    otherValue={searchItem}
                    validateItems={validateNonEmptyItems}
                    height={formRowHeight}
                    labelWidth={labelWidth}
                    fieldWidth={fieldWidth}
                    onChange={(value) => {
                        setReplaceItem(value);
                    }}
                />
                <Row>
                    <EditButtons
                        editPreview={editPreview}
                        onTest={onTest}
                        onRun={onRun}
                        onReset={onReset}>
                    </EditButtons>
                </Row>
                <Row>
                    <InfoArea
                        fieldErrorText={fieldErrorComputedText({
                            dataType: dataType,
                            checkSearchItem: searchItem,
                            checkReplaceItem: replaceItem
                        })}
                        errorText={errorText}
                        infoText={infoText} />
                </Row>
            </Space>
        </Row>
    );
};


export const IdDataSeriesEditor = (props) => {
    const { switchRowSelectionMode, editorMethods } = props;

    const bulkEdit = 1;
    const lineEdit = 2;
    const [selection, setSelection] = useState(bulkEdit);
    const [singleEdit, setSingleEdit] = useState(false);

    const setTestLoaded = editorMethods.setTestLoaded;

    const extendedProps = {
        ...props,
        singleEdit: singleEdit,
        setTestLoaded: setTestLoaded
    };

    return (
        <Space direction={"vertical"}>
            <Row style={{width: '20em'}}>
                <Radio.Group value={selection}
                             onChange={(event) => {
                                 const newSelection = event.target.value;
                                 const newSingleEdit = newSelection === lineEdit;

                                 setSelection(newSelection);
                                 setSingleEdit(newSingleEdit);
                                 switchRowSelectionMode(newSingleEdit);
                             }}>
                    <Radio value={bulkEdit}>Bulk edit</Radio>
                    <Radio value={lineEdit}>Line edit</Radio>
                </Radio.Group>
            </Row>

            {singleEdit && <DataSeriesRecordEditor {...extendedProps} />}
            {!singleEdit && <IdDataSeriesBulkEditor {...extendedProps} />}
        </Space>
    );
};