import {Checkbox, Radio, Row, Space} from "antd";

import {
    formRowHeight,
    DataSeriesField,
    EditButtons,
    InfoArea,
    validator,
    DataSeriesRecordEditor
} from "./DataSeriesDataDisplayCommon";

import React, {useEffect, useState} from "react";


export const StringDataSeriesBulkEditor = (props) => {
    const {
        nonEmptyStringStyle,
        validateNonEmptySearchPattern,
        editPreview,
        errorText,
        infoText,
        editorMethods
    } = props;

    const [searchPattern, setSearchPattern] = useState('');
    const [replacePattern, setReplacePattern] = useState('');

    const [useRegEx, setUseRegEx] = useState(false);

    const labelText = useRegEx ? 'Regex replace' : 'String replace';

    const onUseRegExChange = (event) => {
        setUseRegEx(event.target.checked);
        editorMethods.setTestLoaded(false);
    };

    const onReset = () => {
        setSearchPattern('');
        setReplacePattern('');
    };

    const onRun = () => {
        if (useRegEx) {
            editorMethods.onRegExRun(searchPattern, replacePattern);
        }
        else {
            editorMethods.onReplRun(searchPattern, replacePattern);
        }
    };

    const onTest = () => {
        if (useRegEx) {
            editorMethods.onReplTest(searchPattern, replacePattern);
        }
        else {
            editorMethods.onRegTest(searchPattern, replacePattern);
        }
    };

    useEffect(() => {
        validateNonEmptySearchPattern(searchPattern);

        return () => {
            validateNonEmptySearchPattern(searchPattern);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchPattern]);


    const fieldErrorComputedText = (checkString, fieldErrorText) => {
        if (validator.nonEmptyText(fieldErrorText)) {
            return fieldErrorText;
        }

        if (validator.emptyText(checkString)) {
            return 'Please enter a nonempty search pattern.';
        }
        else {
            return '';
        }
    }


    return (
        <Row size={12} align={"top"}>
            <Space size={12} direction={"vertical"}>
                <Row style={{width: '10em', height: formRowHeight}}>
                    <Checkbox
                        checked={useRegEx}
                        onChange={onUseRegExChange}>
                        {labelText}
                    </Checkbox>
                </Row>
                <DataSeriesField
                    title={"Search"}
                    id={"search-pattern"}
                    label={"Search pattern"}
                    disabled={false}
                    value={searchPattern}
                    basicStyle={nonEmptyStringStyle(searchPattern)}
                    labelWidth={5}
                    fieldWidth={30}
                    height={formRowHeight}
                    onChange={(e => {
                        const v = e.target.value;

                        setSearchPattern(v);
                        editorMethods.setTestLoaded(false);
                        validateNonEmptySearchPattern(v);
                    })}
                />

                <DataSeriesField
                    title={"Replace"}
                    id={"edit-replacement-string"}
                    label={"Replacement string"}
                    disabled={false}
                    value={replacePattern}
                    basicStyle={{}}
                    labelWidth={5}
                    fieldWidth={30}
                    height={formRowHeight}
                    onChange={(e => {
                        const v = e.target.value;

                        setReplacePattern(v);
                        editorMethods.setTestLoaded(false);
                    })}
                />
                <Row>
                    <EditButtons
                        editPreview={editPreview}
                        onTest={onTest}
                        onRun={onRun}
                        onReset={onReset}
                    />
                </Row>
                <Row>
                    <InfoArea
                        fieldErrorText={fieldErrorComputedText(searchPattern)}
                        errorText={errorText}
                        infoText={infoText}
                    />
                </Row>
            </Space>
        </Row>
    );
};


export const StringDataSeriesEditor = (props) => {
    const { switchRowSelectionMode } = props;

    const bulkEdit = 1;
    const lineEdit = 2;
    const [selection, setSelection] = useState(bulkEdit);
    const [singleEdit, setSingleEdit] = useState(false);
    const extendedProps = {
        ...props,
        singleEdit
    };

    return (
        <Space direction={"vertical"}>
            <Row style={{width: '20em'}}>
                <Radio.Group value={selection}
                             onChange={(event) => {
                                 const newSelection = event.target.value;
                                 const newSingleEdit = newSelection === lineEdit;

                                 setSelection(newSelection);
                                 setSingleEdit(newSingleEdit);
                                 switchRowSelectionMode(newSingleEdit);
                             }}>
                    <Radio value={bulkEdit}>Bulk edit</Radio>
                    <Radio value={lineEdit}>Line edit</Radio>
                </Radio.Group>
            </Row>

            {singleEdit && <DataSeriesRecordEditor {...extendedProps} />}
            {!singleEdit && <StringDataSeriesBulkEditor {...props} />}
        </Space>
    );
};