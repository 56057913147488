import React, {useEffect, useState} from "react";
import {
    basicNormalStyle,
    formRowHeight,
    infoUtil,
    validator,
    DataSeriesField,
    EditButtons, InfoArea, DataSeriesRecordEditor
} from "./DataSeriesDataDisplayCommon";
import {Radio, Row, Space} from "antd";


const NumericDataSeriesBulkEditor = (props) => {
    const {
        setTestLoaded,
        editPreview,

        fieldErrorText,
        errorText,
        infoText,
        editorMethods,
        infoAreaTextMethods
    } = props;

    const [factor, setFactor] = useState('1.0');
    const [factorStyle, setFactorStyle] = useState(basicNormalStyle);

    const [offset, setOffset] = useState('0.0');
    const [offsetStyle, setOffsetStyle] = useState(basicNormalStyle);

    const onFactorChanged = e => {
        const v = e.target.value;

        setFactor(v);
        setTestLoaded(false);
    };

    const onOffsetChanged = e => {
        const v = e.target.value;

        setOffset(v);
        setTestLoaded(false);
    };

    useEffect(() => {
        const validateFields = (factor, offset) => {
            const factorValidation = validator.nonZeroNumberValidation(factor);
            const factorInfo = factorValidation.info;
            const offsetValidation = validator.numberValidation(offset);
            const offsetInfo = offsetValidation.info;
            const info = infoUtil.mergeInfos(factorInfo, offsetInfo);

            setFactorStyle(factorValidation.style);
            setOffsetStyle(offsetValidation.style);
            infoAreaTextMethods.setInfo(info);
        };

        validateFields(factor, offset);

        return () => {
            validateFields(factor, offset);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [factor, offset]);


    const onReset = () => {
        setFactor('1.0');
        setOffset('0.0');

        setTestLoaded(false);
    };


    const onRun = () => {
        editorMethods.onScaleRun(factor, offset);
    };


    const onTest = () => {
        editorMethods.onScaleTest(factor, offset);
    };


    return (
        <Row size={12} align={"top"}>
            <Space size={12} direction={"vertical"}>
                <Row style={{height: formRowHeight}}>
                    <span><b>Transform values</b> (x' = ax + b)</span>
                </Row>
                <DataSeriesField
                    title={"Factor (a)"}
                    id={"transform-factor"}
                    label={"Scaling factor"}
                    disabled={false}
                    value={factor}
                    basicStyle={factorStyle}
                    labelWidth={5}
                    fieldWidth={15}
                    height={formRowHeight}
                    onChange={onFactorChanged}
                />
                <DataSeriesField
                    title={"Offset (b)"}
                    id={"transform-offset"}
                    label={"Offset"}
                    disabled={false}
                    value={offset}
                    basicStyle={offsetStyle}
                    labelWidth={5}
                    fieldWidth={15}
                    height={formRowHeight}
                    onChange={onOffsetChanged}
                />
                <Row>
                    <EditButtons
                        editPreview={editPreview}
                        onTest={onTest}
                        onRun={onRun}
                        onReset={onReset}>
                    </EditButtons>
                </Row>
                <Row>
                    <InfoArea
                        fieldErrorText={fieldErrorText}
                        errorText={errorText}
                        infoText={infoText}
                    />
                </Row>
            </Space>
        </Row>
    );
};


// TODO: Move more parameters inside this component.
export const NumericDataSeriesEditor = (props) => {
    const { switchRowSelectionMode } = props;

    const bulkEdit = 1;
    const lineEdit = 2;
    const [selection, setSelection] = useState(bulkEdit);
    const [singleEdit, setSingleEdit] = useState(false);
    const extendedProps = {
        ...props,
        singleEdit: singleEdit
    };


    return (
        <Space direction={"vertical"}>
            <Row style={{width: '20em'}}>
                <Radio.Group value={selection}
                             onChange={(event) => {
                                 const newSelection = event.target.value;
                                 const newSingleEdit = newSelection === lineEdit;

                                 setSelection(newSelection);
                                 setSingleEdit(newSingleEdit);
                                 switchRowSelectionMode(newSingleEdit);
                             }}>
                    <Radio value={bulkEdit}>Bulk edit</Radio>
                    <Radio value={lineEdit}>Line edit</Radio>
                </Radio.Group>
            </Row>

            {singleEdit && <DataSeriesRecordEditor {...extendedProps} />}
            {!singleEdit && <NumericDataSeriesBulkEditor {...extendedProps} />}
        </Space>
    );
};
