import {bgServicesBaseUrl} from "./config";

export const normaliseNumericVal = (val) => {
    if (typeof val === 'string') {
        try {
            return Number(val);
        }
        catch (ex) {
            return undefined;
        }
    }
    else {
        return val;
    }
};


/*
const isString = (val) => {
    return val !== null && val !== undefined && (typeof val === 'string');
};
 */


const isInteger = (val) => {
    return Number.isInteger(normaliseNumericVal(val));
};


const isFloat = (val) => {
    return Number.isFinite(normaliseNumericVal(val));
};


const validateRecalculateArguments = (series_id, offset, factor) => {
    if (!isInteger(series_id)) {
        return "Series number '" + series_id + "' is not an integer.";
    }
    else if (!isFloat(offset)) {
        return "Offset '" + offset + "' is not a number.";
    }
    else if (!isFloat(factor) || factor === 0.0) {
        return "Factor '" + factor + "' is not a non-zero number.";
    }
    else {
        return 'OK';
    }
};


const validateEditDataSeriesArguments = (series_id, jsonPayload) => {
    // Is it meaningful to perform a validation here?
    // Requests are validated by the backend as well.
    return 'OK';
};


const validateStringReplaceArguments = (series_id, searchText, replaceText) => {
    if (!Number.isInteger(normaliseNumericVal(series_id))) {
        return "Series number '" + series_id + "' is not an integer.";
    }
    else if ((typeof searchText !== 'string') || searchText === '') {
        return "Invalid search text '" + searchText + "'.";
    }
    else if (typeof replaceText !== 'string') {
        return "Invalid replace text '" + replaceText + "'.";
    }
    else {
        return 'OK';
    }
};


const validateRegExpReplaceArguments = (series_id, searchRegExp, replacePattern) => {
    if (!Number.isInteger(normaliseNumericVal(series_id))) {
        return "Series number '" + series_id + "' is not an integer.";
    }
    else if ((typeof searchRegExp !== 'string') || searchRegExp === '') {
        return "Invalid regular expression '" + searchRegExp + "'.";
    }
    else if (typeof replacePattern !== 'string') {
        return "Invalid replace pattern '" + replacePattern + "'.";
    }
    else {
        return 'OK';
    }
};


const validateItemReplaceArguments = (series_id, search_id, replace_id) => {
    if (!Number.isInteger(normaliseNumericVal(series_id))) {
        return "Series number '" + series_id + "' is not an integer.";
    }
    else if (!Number.isInteger(normaliseNumericVal(search_id))) {
        return "Search id '" + search_id + "' is not an integer.";
    }
    else if (!Number.isInteger(normaliseNumericVal(replace_id))) {
        return "Replace id '" + replace_id + "' is not an integer.";
    }
    else {
        return 'OK';
    }
};


const sendAPIRequestCommon = (sendRequestFunction, onSuccess, onError) => {
    return sendRequestFunction()
        .then(response => {
            if (200 <= response.status && response.status <= 299) {
                if (onSuccess) {
                    onSuccess(response);
                }
            } else {
                if (onError) {
                    onError(response);
                }
            }
        })
        .catch(ex => {
            if (onError) {
                onError(ex.response);
            }
        });
};


export const sendAPIRequest = (myAxios, apiPath, onSuccess, onError) => {
    const formData = new FormData();
    const postFunction = () => {
        return myAxios.post(bgServicesBaseUrl + apiPath, formData);
    };

    return sendAPIRequestCommon(postFunction, onSuccess, onError);
};


export const sendJSONAPIRequest = (myAxios, apiPath, jsonPayload, onSuccess, onError) => {
    const postFunction = () => {
        return myAxios.post(bgServicesBaseUrl + apiPath, jsonPayload);
    };

    return sendAPIRequestCommon(postFunction, onSuccess, onError);
};


const modifyDataSeries = (parameters) => {
    const {myAxios, validation, apiPath, jsonPayload, onSuccess, onError} = parameters;
    if (validation !== 'OK') {
        return Promise.reject(validation);
    }
    else if (jsonPayload === undefined) {
        sendAPIRequest(myAxios, apiPath, onSuccess, onError);
    }
    else {
        sendJSONAPIRequest(myAxios, apiPath, jsonPayload, onSuccess, onError);
    }
};


export const recalculateNumericDataSeries = (myAxios, series_id, offset, factor, onSuccess, onError) => {
    const series_id_str = '' + series_id;
    const validation = validateRecalculateArguments(series_id_str, offset, factor);
    const apiPath =
        'recalculateNumericDataSeries/' + series_id_str +
        '?offset=' + offset +
        '&factor=' + factor;

    return modifyDataSeries({myAxios, validation, apiPath, onSuccess, onError});
};


export const searchReplaceStringDataSeries = (myAxios, series_id, searchText, replaceText, onSuccess, onError) => {
    const series_id_str = '' + series_id;
    const validation = validateStringReplaceArguments(series_id, searchText, replaceText);
    const apiPath =
        'searchReplaceStringDataSeries/' + series_id_str +
        '?search=' + encodeURIComponent(searchText) +
        '&replace=' + encodeURIComponent(replaceText);

    return modifyDataSeries({myAxios, validation, apiPath, onSuccess, onError});
};


export const regexReplaceStringDataSeries = (myAxios, series_id, searchRegExp, replacePattern, onSuccess, onError) => {
    const series_id_str = '' + series_id;
    const validation = validateRegExpReplaceArguments(series_id, searchRegExp, replacePattern);
    const apiPath =
        'regexReplaceStringDataSeries/' + series_id_str +
        '?search=' + encodeURIComponent(searchRegExp) +
        '&replace=' + encodeURIComponent(replacePattern);

    return modifyDataSeries({myAxios, validation, apiPath, onSuccess, onError});
};


export const replaceIdDataSeries = (myAxios, series_id, search_id, replace_id, onSuccess, onError) => {
    const series_id_str = '' + series_id;
    const validation = validateItemReplaceArguments(series_id, search_id, replace_id);
    const apiPath =
        'replaceIdDataSeries/' + series_id_str +
        '?search=' + search_id +
        '&replace=' + replace_id;

    return modifyDataSeries({myAxios, validation, apiPath, onSuccess, onError});
};


export const editDataSeries = (myAxios, series_id, jsonPayload, onSuccess, onError) => {
    const series_id_str = '' + series_id;
    const validation = validateEditDataSeriesArguments(series_id, jsonPayload);
    const apiPath = 'updateDataSeries/' + series_id_str;

    return modifyDataSeries({myAxios, validation, apiPath, jsonPayload, onSuccess, onError});
};


export const checkDataSeriesJob = (myAxios, series_id, onSuccess, onError) => {
    const series_id_str = '' + series_id;
    const apiPath = bgServicesBaseUrl + 'hasRunningDataSeriesUpdate/' + series_id_str;
    const sendRequestFunction = () => {
        return myAxios.get(apiPath);
    };

    console.log('Checking update queue for data series:', series_id_str);
    return sendAPIRequestCommon(sendRequestFunction, onSuccess, onError);
};